import React, { useEffect } from "react";
import $ from "jquery";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";

const TravelCard = ({ content }) => {
  const handleDateDropdownClick = () => {
    $(".date-time-result").data("daterangepicker").show();
  };

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate()); // Tomorrow's date

    // Initialize DateRangePicker
    const datepicker = $(".date-time-result")
      .daterangepicker(
        {
          opens: "left",
          startDate: today,
          minDate: tomorrow,
        },
        function (start, end, label) {
          console.log(
            "A new date selection was made: " +
              start.format("YYYY-MM-DD") +
              " to " +
              end.format("YYYY-MM-DD")
          );
        }
      )
      .data("daterangepicker");

    // Clean up function
    return () => {
      if (datepicker) {
        datepicker.remove();
      }
    };
  }, []);

  return (
    <div className="date-travel-card position-sticky top-0">
      <div className="price-review">
        <div className="d-flex gap-10 align-items-end">
          <p className="light-pera">From</p>
          <p className="pera">₹{content?.priceForDouble}</p>
        </div>
        <div className="rating">
          <p className="pera">Price varies by group size</p>
        </div>
      </div>
      <h4 className="heading-card">Select Date and Travelers</h4>
      <div className="date-time-dropdown">
        <i className="ri-time-line"></i>
        <p className="date-time-result" onClick={handleDateDropdownClick}>
          Wednesday, Jan 17, 2023
        </p>
      </div>
      <div className="mt-30">
        <button type="submit" className="send-btn w-100">
          Check Availability
        </button>
      </div>
      <div className="footer bg-transparent">
        <h4 className="title">Free Cancellation</h4>
        <p className="pera">Up to 24 hours in advance</p>
      </div>
    </div>
  );
};

export default TravelCard;
